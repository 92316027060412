import service from "../network/axios";
import Store from "@/store";
/**
 * 下单
 * @param {obj} data 下单数据
 */
const order = (data) => {
  return service.post("/api/trade/open.htm", data);
};

/**
 * 持仓
 * @param {boolean} simulate  实盘/模拟
 */
const position = () => {
  const o = {
    tradeType: Store.Quote.simulate ? 2 : 1,
    _: new Date().getTime(),
  };
  return service.get("/api/trade/scheme/holdings", { params: o });
};

/**
 * 限价持仓
 */
const limitPosition = () => {
  const o = {
    tradeType: 1,
    _: new Date().getTime(),
  };
  return service.get("/api/trade/scheme/limit", { params: o });
};
/**
 * 平仓(根据传值是否全部平仓)
 * @param {string} id  商品ID
 * @param {boolean} simulate  实盘/模拟
 */
const close = (id, other) => {
  const o = {
    tradeType: Store.Quote.simulate ? 2 : 1,
  };
  if (id.indexOf(",") !== -1) {
    o.bettingList = id;
    o.source = "一键平仓";
  } else {
    o.bettingId = id;
    o.source = "手动平仓";
  }
  if (other === "reverse") o.source = "反买平仓";
  return service.post("/api/trade/close.htm", o);
};

/**
 * 设置止盈止损
 * @param {object} data bettingId,tradeType,stopProfit,stopLoss,source
 */
const setSPSL = (data) => {
  return service.post("/api/trade/spsl.htm", data);
};

/**
 * 撤销挂单
 * @param {string} id 商品订单ID
 */
const cancelOrder = (id) => {
  const o = {
    bettingId: id,
  };
  return service.post("/api/trade/revoke.htm", o);
};

/**
 * 加模拟币
 */
const addSimulateCoins = () => {
  return service.get("/api/trade/addScore.htm");
};

const tradeRules = (id) => {
  return service.get(`/api/trade/commodity/tradeRules?code=${id}`)
}

export default {
  order,
  position,
  close,
  limitPosition,
  setSPSL,
  cancelOrder,
  addSimulateCoins,
  tradeRules
};
