import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import zh from "./zh.json";

const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
}


let lang = localStorage.getItem("lang")
if(lang === null){
  let browserLang = Intl.DateTimeFormat().resolvedOptions().locale;
let defaultBrowserLang = "";
if (
  browserLang.toLowerCase() === "us" ||
  browserLang.toLowerCase() === "en" ||
  browserLang.toLowerCase() === "en-us"
) {
  defaultBrowserLang = "en";
} else {
  defaultBrowserLang = "en";
}
  lang = defaultBrowserLang
}

i18n.use(initReactI18next).init({
  resources,
  lng: lang ,//localStorage.getItem("lang") ? localStorage.getItem("lang") : getBrowserLang(),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;