import { makeAutoObservable } from "mobx";
import brand from "@/core/brand";

export class BriefStore {
	//搜索数组
	search = [];
	//分组
	group = [];
	configGroup = [];
	homeGroup = [];
	//存放brief更新数据
	data = [];
	configData = [];
	homeData = [];
	total = [];
	favor = [];
	homeDisplay = [];
	initial = true;
	homeInitial = true;
	//热门
	hot = [];
	zones = [];

	constructor() {
		makeAutoObservable(this);
	}

	addSearch(item) {
		this.search.push(item);
	}

	setHot(hot) {
		this.hot = hot;
	}

	getZones(zones) {
		zones.forEach((item) => {
			item.list = item.list.split(";");
		});
		this.zones = zones;
	}

	//todo 保存系统分组
	defaultGroup(name, list, key) {
		this.group.push({ name, list, key });
	}

	addConfigGroup(name, list, key) {
		this.configGroup.push({ name, list, key });
	}

	addHomeGroup(name, list, key) {
		this.homeGroup.push({ name, list, key });
	}

	//todo 从Data传入数据做处理
	addData(total) {
		this.total = total;
		if (brand.code === "XK") {
			this.defaultGroupUpdate(total);
			this.homeGroupUpdate(total);
		} else {
			this.configGroupUpdate(total);
			this.homeGroupUpdate(total);
		}
	}

	defaultGroupUpdate(total) {
		if (this.initial) {
			this.group.unshift({ name: "自选", list: [], key: 0 });
			this.homeDisplay = this.group.pop();
			for (let { name, list, key } of this.group) {
				let n = list.filter((o) => total[o] !== undefined);
				this.data.push({
					name,
					list: n.map((id) => total[id]),
					key: key,
				});
			}
			this.initial = false;
		}
	}

	configGroupUpdate(total) {
		if (this.initial) {
			this.configGroup.unshift({ name: "自选", list: [], key: 0 });
			for (let { name, list, key } of this.configGroup) {
				let n = list.filter((o) => total[o] !== undefined);
				this.configData.push({
					name,
					list: n.map((id) => total[id]),
					key: key,
				});
			}
			this.initial = false;
		}
	}

	homeGroupUpdate(total) {
		if (this.homeInitial) {
			for (let { name, list, key } of this.homeGroup) {
				let n = list.filter((o) => total[o] !== undefined);
				this.homeData.push({
					name,
					list: n.map((id) => total[id]),
					key: key,
				});
			}
			this.homeInitial = false;
		}
	}

	//todo 获取ID字符串
	getSymbol() {
		let n = [];
		brand.code === "XK"
			? this.data.forEach(({ list }) => list.forEach((o) => n.push(o.code)))
			: this.configData.forEach(({ list }) => list.forEach((o) => n.push(o.code)));
		return n.unique().join(",");
	}
}
