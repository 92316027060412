import React from "react";
import { Navigate } from "react-router-dom";
//路由都写在上面
const Home = React.lazy(() => import("@/view/home"));
const Common = React.lazy(() => import("@/view/common"));
const Login = React.lazy(() => import("@/view/common/login"));
const Register = React.lazy(() => import("@/view/common/register"));
const ResetLoginPassword = React.lazy(() =>
  import("@/view/common/resetLoginPwd")
);
const Download = React.lazy(() => import("@/view/download"));
const Invite = React.lazy(() => import("@/view/cooperation/invite"));
const Proxy = React.lazy(() => import("@/view/cooperation/proxy"));
const Activity = React.lazy(() => import("@/view/activity"));
const AboutUs = React.lazy(() => import("@/view/aboutUs"));
const Account = React.lazy(() => import("@/view/account"));
const UserCenter = React.lazy(() => import("@/view/account/userCenter"));
const NameVerify = React.lazy(() => import("@/view/account/nameVerify"));
const LoginHistory = React.lazy(() => import("@/view/account/loginHistory"));
const MyAsset = React.lazy(() => import("@/view/account/myAsset"));
const Recharge = React.lazy(() => import("@/view/account/recharge"));
const Withdraw = React.lazy(() => import("@/view/account/recharge"));
const Bankcard = React.lazy(() => import("@/view/account/bankcard"));
const FundsDetail = React.lazy(() => import("@/view/account/fundsDetail"));
const InviteFriends = React.lazy(() => import("@/view/account/inviteFriends"));
const Trade = React.lazy(() => import("@/view/trade"));
const Announcements = React.lazy(() => import("@/view/announcements"));
const NoMatch = React.lazy(() => import("./404"));
const Agreement = React.lazy(() => import("@/view/agreement"));

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Navigate to="/" />,
  },
  // 登入、註冊、忘記密碼
  {
    path: "/common",
    element: <Common />,
    nestRoutes: [
      {
        path: "/common/login",
        element: <Login />,
      },
      {
        path: "/common/register",
        element: <Register />,
      },
      {
        path: "/common/resetLoginPassword",
        element: <ResetLoginPassword />,
      },
    ],
  },
  {
    path: "/download",
    element: <Download />,
  },
  {
    path: "/cooperation/proxy",
    element: <Proxy />,
  },
  {
    path: "/cooperation/invite",
    element: <Invite />,
  },
  {
    path: "/activity",
    element: <Activity />,
  },
  {
    path: "/aboutUs",
    element: <AboutUs />,
  },
  {
    path: "/agreement",
    element: <Agreement />,
  },
  // 會員中心頁
  {
    path: "/account",
    auth: true,
    element: <Account />,
    nestRoutes: [
      {
        path: "/account/userCenter",
        element: <UserCenter />,
      },
      {
        path: "/account/nameVerify",
        element: <NameVerify />,
      },
      {
        path: "/account/loginHistory",
        element: <LoginHistory />,
      },
      {
        path: "/account/myAsset",
        element: <MyAsset />,
      },
      {
        path: "/account/recharge",
        element: <Recharge />,
      },
      {
        path: "/account/withdraw",
        element: <Withdraw />,
      },
      {
        path: "/account/bankcard",
        element: <Bankcard />,
      },
      {
        path: "/account/fundsDetail",
        element: <FundsDetail />,
      },
      {
        path: "/account/inviteFriends",
        element: <InviteFriends />,
      },
    ],
  },
  // 交易頁
  {
    path: "/trade",
    element: <Trade />,
    nestRoutes: [
      {
        path: "/trade/futures/:id",
        // element: <Login />,
      },
      {
        path: "/trade/crypto/:id",
        // element: <Register />,
      },
      {
        path: "/trade/foreignE/:id",
        // element: <ResetLoginPassword />,
      },
      {
        path: "/trade/stock/:id",
        // element: <ResetLoginPassword />,
      },
    ],
  },
  {
    path: "/announcements",
    element: <Announcements />,
    nestRoutes: [
      {
        path: "/announcements/:id",
        // element: <UserCenter />,
      },
    ],
  },
  // 路由写上面
  {
    path: "*",
    element: <NoMatch />,
  },
];

export default routes;
