import { makeAutoObservable, runInAction } from "mobx";
import EVENT from "../library/api";
import { RESTRICT } from "../library/standard";
import Store from ".";
import { Brand } from "../core";

export class PositionStore {
	check = null;
	data = [];
	simulate = true;
	interval = 3000;
	keepUpdate = true;
	keepUpdateTime = null;
	keepUpdateLimit = true;
	keepUpdateLimitTime = null;
	keepUpdateSpot = true;
	keepUpdateSpotTime = null;
	total = 0;
	totalMargin = 0;
	list = [];
	listData = [];
	limitList = [];
	hide = false;
	spotList = [];
	tempSpotList = [];
	spotListType = 0; //0全部  1卖单  2买单
	//根据moneyType
	rate = [1, 0.1];
	current = 0;
	income = 0;
	idAry = [];

	constructor() {
		makeAutoObservable(this);
	}

	//获取所有商品
	getAll(total) {
		this.check = total;
	}

	addData(data) {
		this.data = data;
		if (this.data && this.listData.length > 0) {
			this.getFutures(this.listData);
		}
	}

	//期货
	async start(status = true) {
		try {
			const { data } = await EVENT.Trade.position();
			this.listData = data;
			this.listData.length > 0 ? (this.keepUpdate = true) : (this.keepUpdate = false);
			//todo 储存订单号
			this.saveId(this.listData);
			this.getFutures(this.listData);
		} catch (e) {
			console.log(e, "PositionStore start");
		} finally {
			if (this.keepUpdate && status) this.keepUpdateTime = setTimeout(() => this.start(), this.interval);
		}
	}

	//期货限价
	async startLimit(status = true) {
		try {
			const { data } = await EVENT.Trade.limitPosition();
			data.length > 0 ? (this.keepUpdateLimit = true) : (this.keepUpdateLimit = false);
			this.getFuturesLimit(data);
		} catch (e) {
			console.log(e, "PositionStore startLimit");
		} finally {
			if (this.keepUpdateLimit && status) this.keepUpdateLimitTime = setTimeout(() => this.startLimit(), this.interval);
		}
	}

	//现货
	async startSpot(status = true) {
		try {
			const { data } = await EVENT.Spot.position();
			if (data.length > 0) {
				this.keepUpdateSpot = true;
				this.interval = 3000;
			} else {
				this.interval = 10000;
			}
			this.tempSpotList = data;
			this.processList();
		} catch (e) {
			console.log(e, "PositionStore spotStart");
		} finally {
			if (this.keepUpdateSpot && status) this.keepUpdateSpotTime = setTimeout(() => this.startSpot(), this.interval);
		}
	}

	end() {
		clearTimeout(this.keepUpdateTime);
		this.keepUpdate = false;
	}

	endLimit() {
		clearTimeout(this.keepUpdateLimitTime);
		this.keepUpdateLimit = false;
	}

	endSpot() {
		clearTimeout(this.keepUpdateSpotTime);
		this.keepUpdateSpot = false;
	}

	//todo 保存持仓ID
	saveId(data) {
		const compare = [];
		data.forEach((o) => compare.push(o.id));
		const close = this.idAry.differ(compare);
		this.removeCloseOrder(close);
		this.idAry.insert(compare);
	}

	removeCloseOrder(close) {
		close.forEach((e) => {
			delete this.list.find((i) => i.id === e);
			this.idAry.remove(e);
		});
		// if (close.length > 0) {
		//   console.log("自动平仓啦！！");
		// }
	}

	//todo获取期货持仓
	getFutures(data) {
		let total = 0;
		let totalMargin = 0;
		this.list = this.futuresProcess(data);
		runInAction(() => {
			if (this.list && this.list.length > 0) {
				this.list.forEach((o) => {
					total = total.add(o.income);
					totalMargin = totalMargin.add(o.margin);
				});
				this.total = total;
				this.totalMargin = totalMargin;
			} else {
				this.list = [];
				this.total = 0.0;
				this.totalMargin = 0;
			}
		});
	}

	getFuturesLimit(data) {
		runInAction(() => {
			this.limitList = this.futuresProcess(data);
		});
	}

	//todo 持仓数据处理
	futuresProcess(data) {
		if (this.check === null) return;
		const o = data.map((e) => {
			let commodity = this.check[e.commodityCode].config;
			e.type = commodity.type;
			e.defer = e.tradeMode && e.tradeMode.indexOf("D") !== -1;
			const rate = this.rate[e.moneyType];
			e.unit = commodity.priceUnit.mul(rate);
			const modal = this.data[commodity.code];
			if (modal) e.pair = modal.des;
			if (modal && modal.price) {
				if (modal.sellPrice !== undefined) {
					//最新结算价
					e.current = e.isBuy ? +modal.buyPrice : +modal.sellPrice;
				} else {
					e.current = +modal.price;
				}
			} else {
				e.current = 0;
			}
			e.crypto = commodity.coins;
			e.priceChange = commodity.priceChange;
			if (!!e.opPrice) {
				if (e.current !== 0) {
					e.isBuy
						? (e.income = e.current.sub(+e.opPrice).div(+e.priceChange).mul(+e.volume).mul(e.unit))
						: (e.income = +e.opPrice.sub(e.current).div(+e.priceChange).mul(+e.volume).mul(e.unit));
				}
				e.stopProfitMin = commodity.stopProfitList[0];
				e.stopProfitMax = commodity.stopProfitList[1];
				if (
					commodity.stopLossType === 2 &&
					commodity.stopType === 1 &&
					RESTRICT.float(+e.income.div(+e.margin), 2) > +commodity.stopRate
				) {
					e.stopLossMin = RESTRICT.float(+e.income, 2).sub(+e.income.mul(commodity.stopRate));
					e.stopLossStepMin = e.income.div(e.unit.mul(e.volume)).toFixed(0);
				} else {
					e.stopLossMin = commodity.stopLossList[0].mul(e.margin);
					e.stopLossStepMin = commodity.stopLossList[0];
				}
				e.stopLossMax = commodity.stopLossList[1];
				//计算 止盈止损价格显示
				if (e.isBuy) {
					e.des = "dir.buy";
					if (e.contractCode.indexOf("USDT") !== -1) {
						e.stopProfitPrice = e.opPrice + e.opPrice.div(e.lever).mul(e.stopProfit.div(e.margin));
						e.stopLossPrice = e.opPrice + e.opPrice.div(e.lever).mul(e.stopLoss.div(e.margin));
					} else {
						e.stopProfitPrice = RESTRICT.float(
							e.opPrice + e.stopProfit.div(e.unit.mul(e.opVolume)).mul(e.priceChange),
							e.priceDigit
						);
						e.stopLossPrice = RESTRICT.float(
							e.opPrice - e.stopLoss.div(-e.unit.mul(e.opVolume)).mul(e.priceChange),
							e.priceDigit
						);
					}

					e.stopProfitPriceMin = (e.opPrice + e.opPrice.div(e.lever).mul(e.stopProfitMin)).toFixed(e.priceDigit);
					e.stopProfitPriceMax = (e.opPrice + e.opPrice.div(e.lever).mul(e.stopProfitMax)).toFixed(e.priceDigit);
					e.stopLossPriceMax = e.opPrice.add(e.opPrice.div(e.lever).mul(e.stopLossMax)).toFixed(e.priceDigit);
					e.stopLossPriceMin = e.opPrice
						.add(e.opPrice.div(e.lever).mul(e.stopLossMin.div(e.margin)))
						.toFixed(e.priceDigit);
				} else {
					e.des = "dir.sell";
					if (e.contractCode.indexOf("USDT") !== -1) {
						e.stopProfitPrice = e.opPrice - e.opPrice.div(e.lever).mul(e.stopProfit.div(e.margin));
						e.stopLossPrice = e.opPrice - e.opPrice.div(e.lever).mul(e.stopLoss.div(e.margin));
					} else {
						e.stopProfitPrice = RESTRICT.float(
							e.opPrice - e.stopProfit.div(e.unit.mul(e.opVolume)).mul(e.priceChange),
							e.priceDigit
						);
						e.stopLossPrice = RESTRICT.float(
							e.opPrice + e.stopLoss.div(-e.unit.mul(e.opVolume)).mul(e.priceChange),
							e.priceDigit
						);
					}
					e.stopProfitPriceMin = (e.opPrice - e.opPrice.div(e.lever).mul(e.stopProfitMax)).toFixed(e.priceDigit);
					e.stopProfitPriceMax = RESTRICT.float(e.opPrice - e.opPrice.div(e.lever).mul(e.stopProfitMin), e.priceDigit);
					e.stopLossPriceMax = RESTRICT.float(e.opPrice.sub(e.opPrice.div(e.lever).mul(e.stopLossMax)), e.priceDigit);
					e.stopLossPriceMin = e.opPrice
						.sub(e.opPrice.div(e.lever).mul(e.stopLossMin.div(e.margin)))
						.toFixed(e.priceDigit);
				}
				e.stopLossPrice = RESTRICT.float(e.stopLossPrice, e.priceDigit);
				e.stopProfitPrice = RESTRICT.float(e.stopProfitPrice, e.priceDigit);
			} else {
				e.nt1 = "0.00%";
				e.nt2 = 0;
				e.income = 0;
			}
			return e;
		});
		return o;
	}

	//todo 平仓
	closePosition(id) {
		return new Promise((resolve, reject) => {
			try {
				let list = [],
					o;
				if (id) {
					o = id;
				} else {
					this.list.forEach((item) => list.push(item.id));
					o = list.join(",");
				}
				const result = EVENT.Trade.close(o);
				// if (Brand.code === "CX") {
				// 	Store.Wallet.updateWallet();
				// }
				resolve(result);
			} catch (err) {
				reject(err);
				console.log(err, "positonStore");
			}
		});
	}

	//todo 现货取消订单
	cancelPending(id) {
		return new Promise((resolve, reject) => {
			try {
				let list = [],
					o;
				if (id) {
					o = id;
				} else {
					this.spotList.forEach((item) => list.push(item.id));
					o = list.join(",");
				}
				const result = EVENT.Spot.cancel(o);
				if (Brand.code === "CX") {
					Store.Wallet.updateWallet();
				}
				resolve(result);
			} catch (err) {
				reject(err);
				console.log(err, "positonStore cancelPending");
			}
		});
	}

	//todo 是否隐藏交易对
	hideDiffOrder(val) {
		this.hide = val;
		this.processList();
	}

	changeSpotType(type) {
		this.spotListType = type;
		this.processList();
	}

	processList() {
		if (this.tempSpotList.length !== 0) {
			if (this.hide) {
				this.spotList = this.tempSpotList.filter((o) => o.commodityName.indexOf(Store.Quote.quote.des) !== -1);
			} else {
				if (this.spotListType === 0) this.spotList = this.tempSpotList;
				if (this.spotListType === 1) this.spotList = this.tempSpotList.filter((o) => o.buy === false);
				if (this.spotListType === 2) this.spotList = this.tempSpotList.filter((o) => o.buy === true);
			}
		} else {
			this.spotList = [];
		}
	}
}
