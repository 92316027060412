import React from "react";
import { Routes, Route } from "react-router-dom";

import routes from "./routes/index";
import { RouterGuard } from "./routes/auth";
import "@/library/prototype";

function MyApp() {
	return (
		<Routes>
			{routes.map((route, i) =>
				route.nestRoutes ? (
					<Route key={i} path={route.path} element={
						<React.Suspense >
								{ RouterGuard(route)}
							</React.Suspense>
						}>
						{route.nestRoutes.map((child) => (
							<Route key={child.path} path={child.path} element={
							<React.Suspense >
								{ RouterGuard(child)}
							</React.Suspense>
							} />
						))}
					</Route>
				) : (
					<Route key={i} path={route.path} element={
							<React.Suspense >
								{ RouterGuard(route)}
							</React.Suspense>
					} />
				)
			)}
		</Routes>
	);
}

export default MyApp;
