import Storage from "@/library/storage";
import service from "@/library/network/axios";

class Contract {
  #initial = false;
  #url = "";
  #key = "_fk_";
  #cache = {};

  constructor(url, key) {
    this.#url = url;
    this.#key += key;
  }

  async init(data) {
    try {
      if (this.#initial) return;
      if (data) {
        this.#cache = data;
      } else {
        let val = await Storage.getItem(this.#key);
        if (val !== null) {
          val = JSON.parse(val);
          this.#cache = val;
          return;
        }
        this.#cache = await service({
          url: this.#url,
          method: "GET",
          timeout: 10000,
          params: {
            _: new Date().getTime(),
          },
        });
      }
      await Storage.setItem(this.#key, JSON.stringify(this.#cache), 300000);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  get cache() {
    return this.#cache;
  }

  get initial() {
    return this.#initial;
  }
}
export default Contract;
